defineDs('DanskeSpil/Domain/Header/Scripts/HeaderNavigation', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/Authentification/Scripts/LoginCache',
  'DanskeSpil/Domain/Authentification/Scripts/LoginController'
], function (Utils, LoginCache, LoginController) {

  // Default variables used throughout the script
  // 950px is the breakpoint where the website changes from desktop navigation to mobile
  // due to the fact that some users on small desktopscreens experienced the mobile navigation

  // var deviceSize = window.screen.width >= 950 ? 'large' : 'small'; // this does not work well for iPad
  // window.matchMedia('(min-width: 950px)').matches moved down to allow turning device

  var $header = $DS('.header');

  var isLoggedIn = function () {
    return document.body.classList.contains('is-logged-in');
  };

  var closeNavigation = function () {
    document.querySelector('.mobile-user-info-inner-wrapper')?.classList.remove('overlay-active');
    document.querySelector('.top-navigation').classList.remove('is-showing-left-menu', 'is-showing-right-menu', 'is-hidden');
    document.querySelector('.top-navigation .brand-nav').removeAttribute('style');

    document.querySelectorAll('.page-area').forEach(($element) => {
      $element.classList.remove('modal-wrapper', 'overlay-active');
    });

    document.querySelectorAll('.menu-toggle-btn').forEach(($element) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      $element.classList.remove('active');
      $element.setAttribute('aria-expanded', false); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    });

    document.documentElement.classList.remove('lock');
    document.body.classList.remove('lock');
  };

  var hideElements = function () {
    if (document.getElementsByClassName('livecasino-subnavigation-wrapper').length > 0) {
      document.body.classList.add('livecasino-with-page-subnav');
    }
  };

  var cookieHelper = function (key, value) {
    const prefixedKey = DS.Config.CONTEXT + key;

    if (typeof value !== 'undefined') {
      const cookieSettings = {
        expires: 7,
        path: '/'
      };
      Utils.cookie(prefixedKey, value || '', cookieSettings);
    }

    return Utils.cookie(prefixedKey);
  };

  var currentBrandURL = function (value) {
    return cookieHelper('currentBrandURL', value);
  };

  var saveCurrentBrandLogoAndURL = function () {
    if (
      document.body.classList.contains('region-frontpage')
      || document.querySelector('.top-navigation-theme-playeraccountmanagement')
      || !document.querySelector('.top-navigation')
    ) {
      return;
    }

    currentBrandURL(document.location.pathname + document.location.search);
  };

  var appendCurrentBrandLogoInPAMnav = function () {
    var returnUrl = Utils.getParameter('returnUrl');
    var isPostLoginRoute = returnUrl ? returnUrl.indexOf('redirect-postlogin') > -1 : false;
    var isPamPageRoute = returnUrl ? returnUrl.indexOf(DS.Config.PRETTYPREFIX) > -1 : false;

    if (returnUrl && !isPostLoginRoute && !isPamPageRoute) { // Never redirect to a postlogin route from DSLogin
      currentBrandURL(returnUrl);
    }

    $DS('body').on('click', '.pam-return-to-site-back, .pam-return-to-site-close, .js-pam-button-return', function () {
      window.location.href = currentBrandURL() || window.location.origin;
    });

    // Register related code
    if (document.querySelector('.region-player-account-management.Register')) {
      document.querySelector('.corporate-nav__logo a')?.setAttribute('href', currentBrandURL());
    }
  };

  var setAriaHiddenAndRemoveTabIndex = function ($el, isHidden) {
    var $subNav = $el.children('.sub-navigation, .sub-sub-sub-navigation');
    $subNav.attr('aria-hidden', isHidden);
    $subNav.find('a').attr('tabindex', isHidden ? -1 : 0);
  };

  var toggleDropdown = function ($el) {
    var $parent = $el.closest('nav');
    $parent.siblings().each(function () {
      var totalHeight = $DS(this).prevAll().find('.has-shown-subnav .sub-navigation').toArray().reduce(function (sum, element) {
        return sum + $DS(element).outerHeight();
      }, 0);

      var elementClass = this.getAttribute('class');
      if (elementClass !== 'reality-check-notification' && elementClass !== 'time-consuming-notification-wrapper') {
        this.style.transform = 'translateY(' + totalHeight + 'px)';
      }
    });
  };

  var hideSubNav = function ($el) {
    $el.removeClass('has-shown-subnav');
    toggleDropdown($el);
    setAriaHiddenAndRemoveTabIndex($el, true);
  };

  var showSubNav = function ($el) {
    // hide all other open subnavs
    hideSubNav($header.find('.has-shown-subnav'));

    // open this subnav
    $el.addClass('has-shown-subnav');
    toggleDropdown($el);
    setAriaHiddenAndRemoveTabIndex($el, false);
  };

  var closeAccountMenu = function () {
    var $accountMenu = $header.find('.accountmenu');
    if ($accountMenu.length) {
      hideSubNav($accountMenu);
    }
  };

  var isMenuMobile = function () {
    return !window.matchMedia('(min-width: 950px)').matches;
  };

  var resizeSikkertSpilCtaAndHeaderStaticFix = function () {
    var brandIcon = document.querySelector('.games-frontpage__list-item');
    var sikkertSpil = document.querySelector('.games-frontpage__list-item.sikkertspil');
    if (sikkertSpil) {
      sikkertSpil.style.width = brandIcon.offsetWidth + 'px';
      sikkertSpil.style.height = brandIcon.offsetHeight + 'px';
      // if sikkertSpil is present it's safe to assume that it's the new header that has been loaded, and we therefore need to adjust the static fix height
      document.querySelector('.header-static-fix').style.height = '37.5rem';
    }
  };

  var bindEvents = function () {
    var $body = $DS('body');

    if (document.querySelector('.top-navigation-theme-corporate')) {
      document.querySelector('.header-static-fix')?.classList.add('custom-static-fix-height');
    }

    // check if we should collapse subnavs
    $body.on('click', function (e) {
      if (!$DS(e.target).parents('header.header').length) {
        // user clicked outside the header area, so we collapse subnavs
        hideSubNav($header.find('.has-shown-subnav'));
      }
    });

    // Needed to be above click to menu to ensure no infinite recursion
    $body.on('click', '.js-logout-trigger', function (e) {
      e.preventDefault();
      e.stopPropagation();
      var $el = this;

      LoginController.logout({
        callback: function () {
          closeAccountMenu();

          // On some pages (eg game pages), they want you redirect back to a specific page after logout
          var overrideReturnUrl = document.body.getAttribute('data-override-logout-return-url');
          if (overrideReturnUrl) {
            window.location.href = overrideReturnUrl;
            return;
          }

          // MIT-1812: Hack for ensuring app logout when Tips, Maaljagt and Dantoto is embedded
          var cookieLogoutSuccessUrl = Utils.cookie('OverrideLogoutUrl');
          if (cookieLogoutSuccessUrl) {
            window.location.href = cookieLogoutSuccessUrl;
            return;
          }


          if ($el?.classList.contains('mobile-only')) {
            closeNavigation();
          }
        }
      });
    });

    // fire() returns true if we can fire and prevents any other firering for half a second
    var fire = function ($el) {
      if ($el && !$el.getAttribute('data-fired')) {  // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        $el.setAttribute('data-fired', 'true');  // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        setTimeout(function () {
          $el.removeAttribute('data-fired');  // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        }, 500);
        return true;
      }
      return false;
    };

    $body.on('click touchstart', '.user-bar .js-has-subnav', function () {
      if (this.classList.contains('has-shown-subnav')) {
        return;
      }

      var isOkToContinue = true;
      var userObj = LoginCache.getUserObj();

      if (!isLoggedIn() || !userObj || !userObj.customerInfo) {
        isOkToContinue = false;
        if (isLoggedIn()) {
          $DS('.js-logout-trigger').trigger('click');
        }
      }

      if (isOkToContinue && fire(this)) {
        var $this = $DS(this);
        hideSubNav($this.parents('.user-bar__navigation').find('.user-bar__list-item').not($this));
        showSubNav($this);
      }
    });

    // Remove href on tablet since otherwise we will have 2 functionalities in landscape
    if (Utils.isTabletDevice()) {
      $DS('.accountmenu__item.profile-btn').removeAttr('href');
    }

    $body.on('click touchstart', '.user-bar .js-has-subnav.has-shown-subnav', function () {
      if (fire(this)) {
        setTimeout(function () { // small delay gives time for other functionality like links and logout
          closeAccountMenu();
        }, 300);
      }
    });

    $header.on('click', '.js-link:not(.games-mobile-nav .js-link)', function (e) {
      var $el = $DS(this).parent();
      var href = $el.children('a').attr('href');
      var isMenuItem = href && href.substring(0, 1) === '#' && href.length === 1;

      if (fire($el.get(0)) && isMenuItem) {
        e.preventDefault();

        var $subNav = $el.children('.sub-navigation, .sub-sub-sub-navigation');

        var subNavIsOpen = $el.hasClass('has-shown-subnav');
        subNavIsOpen ? hideSubNav($el) : showSubNav($el);

        $DS('.games-mobile-nav').toggleClass('static', !subNavIsOpen);

        // calculate and set height of open subnav in mobile menu
        if (isMenuMobile()) {
          var menuItemsHeight = 0;
          var $parents = $el.parents('.sub-navigation');

          if (!subNavIsOpen) {
            menuItemsHeight = $subNav.children().toArray().reduce(function (totalHeight, node) {
              totalHeight += $DS(node).outerHeight();
              return totalHeight;
            }, 0);
          }

          var parentsMenuItemsHeight = $parents.children().toArray().reduce(function (totalHeight, node) {
            totalHeight += $DS(node).outerHeight();
            return totalHeight;
          }, 0);

          var $otherSubnavs = $el.parent().parent().find('.sub-navigation, .sub-sub-sub-navigation').not($subNav);
          $otherSubnavs.css('max-height', 0);
          $subNav.css('max-height', menuItemsHeight).toggleClass('is-open');
          $parents.css('max-height', parentsMenuItemsHeight + menuItemsHeight);
        }
      }
    });

    $DS(window).resize(function () {
      resizeSikkertSpilCtaAndHeaderStaticFix();
    });
  };

  var checkActiveLinks = function () {
    document.querySelectorAll('.top-navigation .js-item').forEach(($item) => {
      var $linkEle = $item.querySelector('.js-link'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      if ($linkEle) {
        var linkHref = $linkEle.getAttribute('href'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        var toggle = window.location.href != window.location.origin + linkHref && window.location.href != linkHref;
        $item.classList.toggle('active', !toggle);
      }
    });
  };

  var initialize = function () {
    bindEvents();
    checkActiveLinks();
    hideElements();
    resizeSikkertSpilCtaAndHeaderStaticFix();
    appendCurrentBrandLogoInPAMnav();
    saveCurrentBrandLogoAndURL();
  };

  initialize();

});
