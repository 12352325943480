defineDs('DanskeSpil/Domain/Authentification/Scripts/UINewLogin', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
], function (Utils) {

  var isDLIContext = function () {
    return DS.Config.CONTEXT === 'DLI';
  };

  var setItemText = function (selector, text) {
    var items = document.querySelectorAll(selector);
    items.forEach(function (item) {
      item.innerText = text;
    });
  };

  var setUsername = function (username) {
    setItemText('.js-username', decodeURIComponent(username));
  };

  var setTotalUserBalance = function (balance) {
    var balanceString = Utils.formatCurrency(balance);
    setItemText('.js-balance-user, .js-balance-total', balanceString);
  };

  var setAvailableUserBalance = function (balance) {
    var balanceString = Utils.formatCurrency(balance);
    setItemText('.js-balance-available', balanceString);
  };

  var setRestrictedUserBalance = function (balance) {
    var balanceString = Utils.formatCurrency(balance);
    setItemText('.js-balance-restricted', balanceString);
  };

  var setBalances = function (data) {
    if (data && data.balance != null) {
      setTotalUserBalance(data.balance);
    }

    if (data && data.availableBalance != null) {
      setAvailableUserBalance(data.availableBalance);
    }

    if (data && data.restrictedBalance != null) {
      setRestrictedUserBalance(data.restrictedBalance);
    }
  };
  var setPreviousLogin = function (prevLogin) {
    setItemText('.js-prevlogintime', decodeURIComponent(prevLogin || ''));
  };

  var showPreviousLogin = function () {
    document.body.classList.add('is-showing-previous-login');

    setTimeout(function () {
      document.body.classList.remove('is-showing-previous-login');
    }, 30000);
  };

  var userIsLoggedIn = function (userObj) {
    if (!userObj || !userObj.customerInfo) {
      console.warn('UINewLogin.userIsLoggedIn: No valid userObj (or only retail)');
      return;
    }

    if (userObj.customerInfo.isRetailAccount === true) {
      document.body.classList.add('is-logged-in');
      console.warn('UINewLogin.userIsLoggedIn: isRetailAccount is true, dont continue');
      return;
    }

    if (isDLIContext()) {
      document.querySelectorAll('.mega-menu__account-details--dli-only').forEach(function (element) {
        element.classList.remove('mega-menu__account-details--dli-only');
      });
    }

    if (userObj.customerInfo.userName) {
      setUsername(userObj.customerInfo.userName);
    }

    if (userObj.customerInfo.userBalance && typeof (userObj.customerInfo.userBalance.totalBalance) === 'number') {
      setTotalUserBalance(userObj.customerInfo.userBalance.totalBalance);
    } else {
      setTotalUserBalance('');
    }

    if (typeof (userObj.customerInfo.availableBalance) === 'number') {
      setAvailableUserBalance(userObj.customerInfo.availableBalance);
    } else {
      setAvailableUserBalance('');
    }

    if (typeof (userObj.customerInfo.restrictedBalance) === 'number') {
      setRestrictedUserBalance(userObj.customerInfo.restrictedBalance);
    } else {
      setRestrictedUserBalance('');
    }

    if (userObj.customerInfo.prevLoginTime) {
      setPreviousLogin(userObj.customerInfo.prevLoginTime);
    }

    document.body.classList.add('is-logged-in');
  };

  var userIsLoggedOut = function (redirect) {
    redirect = (typeof redirect !== 'undefined') ? redirect : true;
    var isLoggedIn = document.body.classList.contains('is-logged-in');

    document.body.classList.remove('is-logged-in');
    setItemText('.js-username, .js-balance-user', '');

    var cookieLogoutSuccessUrl = Utils.cookie('OverrideLogoutUrl');
    if (redirect && isLoggedIn && window.location.pathname.indexOf(DS.Config.PRETTYPREFIX) === 0 && !cookieLogoutSuccessUrl) {
      window.location.href = Utils.cookie(DS.Config.CONTEXT + 'currentBrandURL') || window.location.origin;
    }

  };

  // public functions
  var publc = {
    showPreviousLogin: showPreviousLogin,
    userIsLoggedIn: userIsLoggedIn,
    userIsLoggedOut: userIsLoggedOut,
    setBalances: setBalances,
    setRestrictedUserBalance: setRestrictedUserBalance,
    setTotalUserBalance: setTotalUserBalance
  };

  return publc;
});
