defineDs('DanskeSpil/Domain/Authentification/Scripts/LoginCache', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils'
], function (Utils) {

  const USEROBJ_KEY = 'DS_USER_SESSION';
  const LOGGED_IN_LOCAL_TIME_KEY = 'loggedInTime';
  const USEROBJ_BOSSO_HASH_KEY = 'DS_USER_BOSSO_HASH';

  /* Logged in local time is used in order to show previousLogin time in 30sec across page reload */
  const refreshLoggedInLocalTime = function (context = DS.Config.CONTEXT) {
    Utils.setSessionStorage(context + LOGGED_IN_LOCAL_TIME_KEY, (new Date()).getTime());
  };

  const getLoggedInLocalTime = function (context = DS.Config.CONTEXT) {
    return Utils.getSessionStorage(context + LOGGED_IN_LOCAL_TIME_KEY);
  };

  const getBossoHash = function (context) {
    const bossoCookieMap = {
      DLI: 'BOSSOisLoggedIn',
      DLO: 'BOSSOisLoggedInDLO'
    };

    const bossoCookie = bossoCookieMap[context];
    if (bossoCookie) {
      return Utils.cookie(bossoCookie);
    }
  };

  const setUserObj = function (userObj, context = DS.Config.CONTEXT) {
    userObj = userObj || {};
    Utils.setSessionStorage(context + USEROBJ_KEY, JSON.stringify(userObj));
    Utils.setSessionStorage(context + USEROBJ_BOSSO_HASH_KEY, getBossoHash(context));
  };

  const clearCache = function (context = DS.Config.CONTEXT) {
    setUserObj('', context);
  };

  const getUserObj = function (context = DS.Config.CONTEXT) {
    const userObjBossoHash = Utils.getSessionStorage(context + USEROBJ_BOSSO_HASH_KEY);
    // If our stored hash doesnt match current bosso cookie value
    // We wont use this cached userObj. Actually we then will clear cache.
    if (!userObjBossoHash || userObjBossoHash != getBossoHash(context)) {
      clearCache(context);
      return false;
    }

    var str = Utils.getSessionStorage(context + USEROBJ_KEY);
    try {
      return (JSON.parse(str || null) || false);
    } catch (e) {
      return false;
    }
  };

  const getDliDloUserObj = function () {
    return [
      getUserObj('DLI'),
      getUserObj('DLO')
    ];
  };

  const isLoggedIn = function () {
    var userObj = getUserObj();
    return (userObj && typeof userObj.customerInfo !== 'undefined');
  };

  const getPlayerId = () => {
    const player = getUserObj();

    if (!player || !player.customerInfo) {
      return null;
    }

    return player.customerInfo.customerId;
  };

  // public functions
  return {
    clearCache,
    getLoggedInLocalTime,
    getUserObj,
    getDliDloUserObj,
    isLoggedIn,
    refreshLoggedInLocalTime,
    setUserObj,
    getPlayerId,
    getBossoHash
  };

});
