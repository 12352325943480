defineDs('DanskeSpil/Domain/Overlays/Scripts/Overlay', [
  'Common/Framework/EventHandling/Scripts/Event',
  'DanskeSpil/Project/Design/Js/Global/HashChange',
  'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
  'Shared/Framework/Mithril/Scripts/Helpers/Utils'
], function (Event, HashChange, ApiRequest, Utils) {

  /*
   * Variables
   */
  const defaultOptions = {
    group: 'main',
    clearHashOnClose: 'myHash', // yes, no, myHash
    closeOnClearHash: false, // true, false
    preventCloseAll: false,
  };

  const overlays = {};
  let counter = 0;
  let $overlayWrapper = null;

  /*
   * Functions
   */

  const isPreventCloseAll = function () {
    // This is to prevent people from closing login with dataprocessing
    return (Object.keys(overlays).filter(function (key) { return overlays[key].preventCloseAll; }).length > 0);
  };

  const close = function (params) {
    if (params.id) {

      var id = params.id;
      var options = overlays[id];

      if (!options) {
        console.log('Overlay.close id ' + id + ' not found');
      }

      $overlayWrapper?.querySelector('#' + id)?.remove(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element

      if (typeof options.closeHook === 'function') {
        options.closeHook();
      }

      if (options.clearHashOnClose == 'yes' || (options.clearHashOnClose == 'myHash' && options.myHash == location.hash)) {
        HashChange.clearHash('closeOverlay');
      }

      delete overlays[id];

    } else if (params.group) {
      Object.entries(overlays).forEach(([k, v]) => {
        if (v.group === params.group) {
          close({ id: k, cleanUp: false });
        }
      });
    }

    if (typeof params.cleanUp === 'undefined' || params.cleanUp === true) {
      cleanUpAfterClose();
    }
  };

  const closeAll = function () {
    if (isPreventCloseAll()) {
      return;
    }

    // Trigger close callback for every element
    $overlayWrapper?.querySelectorAll('.js-overlay-container').forEach(($element) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      var id = $element.getAttribute('id'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      close({ id: id });
    });

    cleanUpAfterClose();
  };

  const cleanUpAfterClose = function () {
    if ($overlayWrapper && $overlayWrapper.querySelector('.js-overlay-container') === null) { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      document.body.classList.remove('overlay-enabled');
      $overlayWrapper?.remove(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      $overlayWrapper = null;
    }
  };

  const open = function (options = {}) {
    Utils.logDeprecated('Domain/Overlays/Scripts/Overlay.js - open', options); // Added by MIPE 2022-08-04

    options = Object.assign(defaultOptions, options);
    var id = 'js-generated-overlay-container-' + counter++;

    // Close all other existing in that group
    if (options.group) {
      close({ group: options.group, cleanUp: false });
    }

    // If clearHashOnClose is 'my' and no myHash is defined, save current hash into that value
    if (options.clearHashOnClose == 'myHash' && !options.myHash) {
      options.myHash = location.hash;
    }

    // Push options to config
    overlays[id] = options;

    // Create wrapper is not exists
    if ($overlayWrapper === null) {
      $overlayWrapper = document.createElement('div');
      $overlayWrapper.classList.add('overlay-wrapper', 'js-outside-overlay');
      $overlayWrapper.addEventListener('click', closeAll); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      document.body.appendChild($overlayWrapper);
    }

    // Create new container
    var $container = document.createElement('div');
    $container.id = id;
    $container.classList.add('overlay-container', 'js-overlay-container');
    $overlayWrapper.appendChild($container); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element

    // Setup complete function, which runs after content is appended
    var complete = function () {
      document.body.classList.add('overlay-enabled');

      $container?.querySelector('.js-close-overlay')?.addEventListener('click', function (event) { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        event.preventDefault();
        event.stopPropagation();
        close({ id: id });
      });

      $container?.addEventListener('click', function (event) { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        event.stopPropagation(); // prevents to trigger click event on the parent $overlayWrapper

        if (event.target === this) {
          if (isPreventCloseAll()) {
            return;
          }
          close({ id: id });
        }
      });

      Event.fire('overlay-opened', { $container });

      if (typeof options.onComplete === 'function') {
        options.onComplete();
      }
    };

    if (overlays[id].ajaxUrl) {
      ApiRequest({
        url: overlays[id].ajaxUrl,
        deserialize: (response) => { return response; } // No JSON parsing
      }).then((html) => {
        $container.innerHTML = html;
        complete();
      });

    } else {
      console.error('Overlay.open error: no ajaxUrlcp given.');
    }
  };

  const onHashCleared = function () {
    console.debug('Overlay.onHashCleared');
    Object.entries(overlays).forEach(([k, v]) => {
      console.debug('  id: ' + k);
      if (v.closeOnClearHash === true) {
        console.debug('    close id: ' + k);
        close({ id: k });
      }
    });
  };

  const initialize = function () {
    // Attach route pattern for Sitecore overlayed pages
    HashChange.match(/^p\//, function (hash) {
      var overlayUrl = hash.substring(1) + (hash.indexOf('?') === -1 ? '?' : '&') + 'overlay=1';
      open({ ajaxUrl: overlayUrl, closeOnClearHash: true });
    });

    // Triggered from ds.hashChange
    Event.subscribe('overlay-hash-cleared', onHashCleared);
  };

  initialize();

});
