defineDs('DanskeSpil/Domain/Onboarding/Scripts/Helpers/Utils',
  [
    'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
    'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  ],
  function (ApiRequest, Utils) {

    var prepareParamString = function (options) {
      options = options || {};
      var keys = Object.keys(options);
      var params = [];

      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        var val = options[key];

        if (val && val instanceof Array) {
          val = val.join(',');
        }

        params.push(key + '=' + val);
      }

      return params.length ? '?' + params.join('&') : '';
    };

    var getOnboardData = function (options, baseURL) {
      var params = prepareParamString(options);

      return ApiRequest({
        url: baseURL + params,
        localCacheTTL: 60,
        unwrapSuccess: function (response) {
          return response;
        }
      });
    };

    var getDevice = function () {
      if (Utils.isApp()) return 'app';
      if (Utils.isMobile()) return 'mobile';
      if (Utils.isTabletDevice()) return 'tablet';
      if (Utils.isDesktop()) return 'desktop';
    };

    // Public functions:
    return {
      prepareParamString: prepareParamString,
      getOnboardData: getOnboardData,
      getDevice: getDevice
    };
  });
