defineDs('Common/Framework/EventHandling/Scripts/CrossWindowEvents', function () {

  var lastMessageCounter;
  var subscribers = [];
  var prefix = DS.Config.CONTEXT + '-'; // DLI/DLO prefix

  var supported = function () {
    try {
      localStorage.getItem(prefix + 'storage-check');
      localStorage.setItem(prefix + 'storage-check', 1);

      return true;
    } catch (e) {}

    return false;
  }();

  var getItem = function (name) {
    if (supported) {
      return window.localStorage.getItem(prefix + name);
    }
  };
  var setItem = function (name, value) {
    if (supported) {
      window.localStorage.setItem(prefix + name, value);
    }
  };
  var removeItem = function (name) {
    if (supported) {
      window.localStorage.removeItem(prefix + name);
    }
  };

  /** ************************************************************************************
    * Fire event will store the event in local storage in the form { name, data }. The key
    * 'ds-window-event-message-[counter]' includes a 'ds-window-event-counter' that is
    * incremented with each message and also stored in localStorage.
    */
  var fire = function (name, data) {
    console.log('CrossWindowEvents.fire(' + name + ')');
    console.log(data);
    lastMessageCounter++;
    var message = { name: name, data: data };
    setItem('ds-window-event-message-' + lastMessageCounter, JSON.stringify(message));
    setItem('ds-window-event-counter', lastMessageCounter);
    dispatchEvent(message); // fire the message locally
  };

  var subscribe = function (name, callback) {
    console.log('CrossWindowEvents.subscribe(' + name + ')');
    subscribers.push({ name: name, callback: callback });
  };

  var dispatchEvent = function (message) {
    console.log('CrossWindowEvents.dispatchEvent()');
    console.log(message);
    console.log('subscribers (all event types):');
    console.log(subscribers);
    subscribers.forEach(function (item) {
      if (message.name === item.name) {
        try {
          item.callback(message.data);
        } catch (err) { // error in external code should not break the loop
          console.error('Exception catched in CrossWindowEvents.dispatchEvent() thrown in listener callback:');
          console.error(err);
        }
      }
    });
  };

  var getMessage = function (counter) {
    var messageJson = getItem('ds-window-event-message-' + counter);
    if (messageJson) {
      var message = JSON.parse(messageJson);
      dispatchEvent(message);
      lastMessageCounter = counter;
    }
  };

  var cleanupMessages = function () {
    if (lastMessageCounter > 10) {
      var i = lastMessageCounter - 10;
      while (getItem('ds-window-event-message-' + i) !== null) {
        removeItem('ds-window-event-message-' + i);
        i--;
      }
    }
  };

  // Init:
  lastMessageCounter = getItem('ds-window-event-counter');

  // Watcher. This is a work-around while storage event is not working in Chrome when setting document.domain.
  setInterval(function () {
    var newMessageCounter = getItem('ds-window-event-counter');
    if (newMessageCounter != lastMessageCounter) {
      newMessageCounter = Number(newMessageCounter);
      if (newMessageCounter > lastMessageCounter + 1) { // several messages arrived?
        for (var i = lastMessageCounter + 1; i <= newMessageCounter; i++) {
          getMessage(i);
        }
      } else {
        getMessage(newMessageCounter);
      }
    }
  }, 100);

  // Cleanup on init
  cleanupMessages();

  // Cleanup every 10 min for avoiding QUOTA_EXCEEDED_ERR when users stays on same page for hours or days
  setInterval(cleanupMessages, 10 * 60 * 1000);

  return {
    fire: fire,
    subscribe: subscribe
  };

});
