defineDs('DanskeSpil/Domain/Header/Scripts/Components/RealityCheckTimeNotification', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'Shared/Framework/Mithril/Scripts/Helpers/Dictionary',
  'Shared/Framework/Mithril/Scripts/Helpers/DOMUtils',
  'Common/Framework/EventHandling/Scripts/Event',
  'Common/Framework/EventHandling/Scripts/CrossWindowEvents',
  'DanskeSpil/Framework/TimeUtils/Scripts/TimeUtils',
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/Authentification/Scripts/LoginCache',
  'DanskeSpil/Domain/Authentification/Scripts/LoginController',
  'Shared/Framework/Ensighten/Scripts/Ensighten',
  'Shared/Framework/Mithril/Scripts/Helpers/Storage',
  'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest'
], function (Component, Dictionary, DOMUtils, Event, CrossWindowEvents, TimeUtils, Utils, LoginCache, LoginController, Ensighten, Storage, ApiRequest) {

  var data = null;
  var waitingForData = false;
  var minRequestInterval = 60 * 60 * 1000;
  var storageKey = `${LoginCache.getBossoHash(DS.Config.CONTEXT)}_RealityCheckNotification`;
  var setTimeoutObject = null;
  var RealityCheckDictionary = new Dictionary('/RealityCheckNotification');

  Component('reality-check-time-notification', [RealityCheckDictionary], function (m, route) {

    // TODO: Remove this dev hack from the production code!
    // This is only for testing #########################
    window.mockRealityCheck = function (minutes) {
      ApiRequest({
        url: DS.Config.CONTEXTPREFIX + '/scapi/danskespil/realitycheck/v1/setCheckNotification/' + minutes
      });
      deleteData();
      return 'mockRealityCheck(' + minutes + ') OK';
    };
    // ##################################################

    var parentElement = this.$element; // eslint-disable-line no-jquery/variable-pattern -- Reason: Not a jquery element
    var d = RealityCheckDictionary.get;

    var isDataValid = function (data) {
      var userObj = LoginCache.getUserObj();
      return (data && userObj && (!data.userName || (data.userName && userObj.customerInfo.userName == data.userName)));
    };

    var deleteData = function () {
      Storage.set(storageKey, null);
    };

    var storeData = function (data) {
      if (data.displayNotification == false) { // when no display, strip unused data
        data = { displayNotification: false, nextCheckAt: data.nextCheckAt, lastRequest: data.lastRequest };
      }
      var userObj = LoginCache.getUserObj();
      if (userObj && userObj.customerInfo) {
        data.userName = userObj.customerInfo.userName;
      }

      var secondsToNextCheck = (new Date(data.nextCheckAt) - new Date()) / 1000;

      Storage.set(storageKey, JSON.stringify(data), Math.round(secondsToNextCheck));
    };

    var getData = function () {
      if (waitingForData) return; // request has already been sent

      if (LoginCache.isLoggedIn()) {
        var realityData = JSON.parse(Storage.get(storageKey));
        if (isDataValid(realityData)) {
          data = realityData;
          setNextUpdate(data);
          m.redraw(false);
          Event.fire('header-area-height-changed');
        } else {
          deleteData();
          requestRealityCheck();
        }
      }
    };

    // find the next update time and starte a timer
    var setNextUpdate = function (data) {
      var nextUpdateTime = new Date(data.nextCheckAt).getTime();
      var timeToNextUpdate = nextUpdateTime - TimeUtils.getServerDateTime().getTime();
      if (data.displayNotification && minRequestInterval < timeToNextUpdate) {
        // if display is visible, get an updated time and amount
        timeToNextUpdate = minRequestInterval;
        data.nextCheckAt = new Date(new Date().getTime() + timeToNextUpdate);
        storeData(data);
      }

      if (timeToNextUpdate <= 60000) {
        timeToNextUpdate = 60000; // minimum one minute
      }

      // If a setTimeout is already started, cancel that.
      if (setTimeoutObject) {
        clearTimeout(setTimeoutObject);
      }

      setTimeoutObject = setTimeout(function () {
        requestRealityCheck();
      }, timeToNextUpdate);
    };

    CrossWindowEvents.subscribe('ds.event.userLoggedIn', function () {
      // Not needed to call the service the first hour
      // requestRealityCheck();

      var nextCheckAt = new Date(new Date().getTime() + 3600000);
      data = { displayNotification: false, nextCheckAt: nextCheckAt };
      storeData(data);
      setNextUpdate(data);
    });

    CrossWindowEvents.subscribe('ds.event.userLoggedOut', function () {
      deleteData();
      data = null;
      m.redraw(false);
    });

    CrossWindowEvents.subscribe('ds.event.realityCheck.newDataReceived', function (newData) {
      data = newData;
      m.redraw(false);
      Event.fire('header-area-height-changed');
    });

    var requestRealityCheck = function () {
      if (data && data.lastRequest) {
        var lastEpoch = new Date(data.lastRequest).getTime();
        var nowEpoch = new Date().getTime();
        if (nowEpoch - lastEpoch < 60000) {
          return; // abort if last request is less than one minute
        }
      }

      waitingForData = true;

      ApiRequest({
        url: DS.Config.CONTEXTPREFIX + '/scapi/danskespil/realitycheck/v1/checkNotification',
        localCacheTTL: 60,
        requireAuthCookie: true
      }).then(function (response) {
        if (response.status == 'success') {
          data = response.data;
          data.lastRequest = new Date();
          storeData(data);
          CrossWindowEvents.fire('ds.event.realityCheck.newDataReceived', data);
          setNextUpdate(data);
          waitingForData = false;
          if (data.displayNotification) {
            Ensighten.pushGaEvent('tidsnotifikation', 'blevet_vist', getTrackingLabel(data));
          }
        }
      });
    };

    var getTrackingLabel = function (data) {
      var label = data.loginHours + ':' + twoDigitsStr(data.loginMinutes);
      if (data.difference != 0) {
        label += data.difference > 0 ? '_overskud_' : '_underskud_';
        label += getFormattedAmount(data.difference) + 'kr.';
      }
      return label;
    };

    var requestAccept = function () {
      ApiRequest({
        url: DS.Config.CONTEXTPREFIX + '/scapi/danskespil/realitycheck/v1/acceptRealityCheckNotification/' + data.notificationId,
        requireAuthCookie: true
      });
    };

    var showStaticFix = function (show) {
      var elements = document.getElementsByClassName('reality-check-static-fix');
      if (elements.length > 0) {
        elements[0].style.display = show ? 'block' : 'none';
        if (show) {
          setTimeout(setStaticFixHeight, .5);
        }
      }
    };

    var setStaticFixHeight = function () {
      var $header = document.querySelector('header.top-navigation');
      var $staticFix = document.querySelector('.reality-check-static-fix');
      if (!$header || !$staticFix) {
        return;
      }

      const position = getComputedStyle($header).position;
      if (position == 'fixed' || position == 'absolute') {
        var $notification = $header.querySelector('.reality-check-notification'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        $staticFix.style.height = DOMUtils.calculateElementOuterHeight($notification) + 'px';
      } else {
        $staticFix.style.height = 0;
      }
    };

    var twoDigitsStr = function (number) {
      if (number < 10) {
        return '0' + number;
      }
      return number.toString();
    };

    // Get local time in format 'h.mm.ss'
    var getFormattedTime = function (epoc) {
      var date = new Date(epoc); // local time zone
      var minutes = twoDigitsStr(date.getMinutes());
      return date.getHours() + '.' + minutes;
    };

    // amount in cents converted to decimals '1,10' or integer if cents=0
    var getFormattedAmount = function (amount) {
      amount = Math.abs(amount);
      amount /= 100;
      if (amount - Math.floor(amount) > 0) {
        amount = amount.toFixed(2);
        return amount.replace('.', ',');
      }
      return amount.toString();
    };

    // formattet amount in strong always positive but with plus-minus class and 'kr.'
    // ex: '<strong class="amount-minus">100 kr.</strong>'
    var getAmountString = function (amount) {
      var plusminus = (amount < 0) ? 'minus' : 'plus';
      return '<strong class="amount-' + plusminus + '">' + getFormattedAmount(amount) + ' kr.</strong>';
    };

    var replace = function (text) {
      if (typeof text !== 'string') return;

      // expected text: 'Kl. {time}  har du været <b>logget ind i {hours} time{r}{ og {minutes} minut{ter}}</b> og har i perioden haft et underskud på {amount}.'
      var epoc = new Date(data.createdAt).getTime();
      text = text.replace('{time}', getFormattedTime(epoc));
      text = text.replace('{hours}', data.loginHours);
      text = text.replace('{r}', data.loginHours > 1 ? 'r' : '');
      text = text.replace('{hours}', data.loginHours);
      text = text.replace('{ og {minutes} minut{ter}}', data.loginMinutes > 0 ? ' og {minutes} minut{ter}' : '');
      text = text.replace('{minutes}', data.loginMinutes);
      text = text.replace('{ter}', data.loginMinutes != 1 ? 'ter' : '');
      if (text.indexOf('{amount}') > -1) {
        text = text.replace('{amount}', getAmountString(data.difference));
      }
      return text;
    };

    var getPrimaryText = function (data) {
      if (data.ruleVariant == 'onlyTimeAbove24' || data.ruleVariant == 'above24HoursAndGame') {
        if (data.difference > 0) {
          return replace(d('TextPlus24h'), data);
        } else if (data.difference < 0) {
          return replace(d('TextMinus24h'), data);
        }
        return replace(d('TextZero24h'), data);
      }
      if (data.difference > 0) {
        return replace(d('TextPlus'), data);
      } else if (data.difference < 0) {
        return replace(d('TextMinus'), data);
      }
      return replace(d('TextZero'), data);
    };

    var getSecondaryText = function (data) {
      if (data.designVariant == 'warning') {
        return d('TextWarn');
      }
      return d('TextNoWarn');
    };

    var root = {

      controller: function () {

        // continue button pressed
        this.continue = function (event) {
          event.preventDefault();
          Ensighten.pushGaEvent('tidsnotifikation', 'klik_paa_fortsaet', getTrackingLabel(data));
          data.displayNotification = false;
          requestAccept();
          storeData(data);
          CrossWindowEvents.fire('ds.event.realityCheck.newDataReceived', data);
        };

        // cancel button pressed
        this.cancel = function (event) {
          event.preventDefault();
          Ensighten.pushGaEvent('tidsnotifikation', 'klik_paa_log_ud', getTrackingLabel(data));
          var cancelLink = data.cancelLink;
          LoginController.logout({
            callback: function () {
              if (window.location.toString().indexOf('newWindow') != -1) {
                window.close();
              } else {
                window.location.href = cancelLink;
              }
            }
          });
        };

        this.gotoHistory = function () {
          Ensighten.pushGaEvent('tidsnotifikation', 'klik_paa_historik', getTrackingLabel(data));
          window.location.href = data.accountHistoryLink;
        };

        this.openResponsibleGaming = function () {
          Ensighten.pushGaEvent('tidsnotifikation', 'klik_paa_omtanke', getTrackingLabel(data));
          window.open(
            data.responsibleGamingLink,
            d('PlayWithThoughtLinkText'),
            'height=600'
          );
        };

        if (data == null) {
          getData();
        }
      },

      view: function (ctrl) {
        if (data == null || !data.displayNotification) {
          showStaticFix(false);
          return;
        }

        if (parentElement.classList.contains('reality-check-notification-fallback')) {
          if (document.querySelector('header.top-navigation') !== null) {

            var $fallback = document.querySelector('.reality-check-notification-fallback');

            if (document.getElementsByClassName('livecasino-game-spot-iframe-outer-wrapper maximized').length === 1
              || document.getElementsByClassName('livecasino-game-spot-iframe-outer-wrapper fullscreen').length === 1
              || document.getElementsByClassName('gamelauncher-iframe-outer-wrapper maximized').length === 1
              || document.getElementsByClassName('gamelauncher-iframe-outer-wrapper fullscreen').length === 1
              || (document.getElementsByClassName('AvalonGVCBingospilside').length === 1 && document.getElementsByClassName('gamelauncher-iframe-outer-wrapper').length === 1)) {

              if ($fallback) {
                $fallback.style.zIndex = '1000';

                if (!Utils.isMobile()) {
                  $fallback.style.top = '3.6rem';
                }
                if (Utils.isTabletDevice() && (Math.abs(window.orientation) !== 90)) { // not portrait) {
                  $fallback.style.top = '3.2rem';
                }
              }

            } else {
              showStaticFix(false);
              return;
            }
          }
        }

        showStaticFix(true);

        return m('.reality-check-notification__content', {
          class: data.designVariant ? 'reality-check-notification__content--' + data.designVariant : null
        }, [
          m('.reality-check-notification__text', [
            m('div', m.trust(getPrimaryText(data))),
            m('span', m.trust(getSecondaryText(data))),

            data.showResponsibleGamingLink || data.showAccountHistoryButton ?
              m('span', [
                ' ',
                data.showResponsibleGamingLink ? [
                  m('a', {
                    href: '#',
                    onclick: ctrl.openResponsibleGaming
                  }, m('strong', d('PlayWithThoughtLinkText'))),
                  ' | '
                ] : null,
                data.showAccountHistoryButton ?
                  m('a', {
                    href: '#',
                    onclick: ctrl.gotoHistory
                  }, d('AccountHistoryLinkText')) :
                  null
              ])
              : null,
          ]),
          m('.reality-check-notification__button-container', [
            data.showContinueButton ? m('a.reality-check-notification__button.reality-check-notification__button--continue', {
              href: '#',
              onclick: ctrl.continue
            }, d('ContinueLinkText')) : null,
            data.showCancelButton ? m('a.reality-check-notification__button.reality-check-notification__button--cancel', {
              href: '#',
              onclick: ctrl.cancel
            }, m('div', m.trust(d('CancelLinkText')))) : null
          ])
        ]);
      }
    };

    route('/', root);
  });
});
