/* global dataLayer */
defineDs('Shared/Framework/Ensighten/Scripts/Ensighten', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/Authentification/Scripts/LoginCache',
  'Common/Framework/EventHandling/Scripts/Event',
  'Common/Framework/EventHandling/Scripts/CrossWindowEvents'
], function (Utils, LoginCache, Event, CrossWindowEvents) {
  // Variables:
  var localDataLayer = [];
  const sessionStorageGaEventTracking = 'DS_ENSIGHTEN_GAEVENT_TRACKING_ON_PAGE_REDIRECT';
  const sessionStoragePromoClickTracking = 'DS_ENSIGHTEN_PROMO_CLICK_TRACKING_ON_PAGE_REDIRECT';

  /**
   * Return the selected brand on the current page if we have it
   *
   * @returns {string}  The brand identifier
   */
  var getBrand = function () {
    return typeof window.dataLayer !== 'undefined' && (window.dataLayer[0]?.page?.brand || '');
  };

  var getContext = function () {
    if (typeof DS !== 'undefined' && DS.Config) {
      return DS.Config.CONTEXT;
    }
    return '';
  };

  var pushVirtualPage = function (name, path, type) {
    path = path.split(/[#|?]/)[0];
    if (typeof window.dataLayer !== 'undefined') {
      if (!type) {
        type = '';
      }

      if (path !== undefined && path.charAt(0) === '/') {
        path = path.substring(1);
      }

      window.dataLayer.push({
        event: 'virtualPage',
        page: {
          virtualPath: '/virtualpath/' + path?.toLowerCase(),
          name: name.toLowerCase(),
          type: type.toLowerCase()
        }
      });
    }
  };

  var pushHashAsVirtualPage = function (name, type) {
    if (!type) {
      type = window.dataLayer[0]?.page?.type || '';
    }
    var dsPrettyPrefix = DS.Config.PRETTYPREFIX || '';
    pushVirtualPage(name, dsPrettyPrefix + '/' + name, type);
  };

  var pushPromoView = function (promotions) {
    promotions = returnAsArray(promotions);

    var trackData = {
      event: 'promoView',
      ecommerce: {
        promoView: {
          promotions: promotions
        }
      }
    };

    window.dataLayer.push(trackData);
  };

  var pushPromoClick = function (promotions) {
    promotions = returnAsArray(promotions);

    var trackData = {
      event: 'promoClick',
      ecommerce: {
        promoClick: {
          promotions: promotions
        }
      }
    };

    window.dataLayer.push(trackData);
  };

  const pushPromoClickOnPageRedirect = (promotions) => {
    sessionStorage.setItem(sessionStoragePromoClickTracking, JSON.stringify({
      promotions,
      path: window.location.pathname + window.location.hash,
    }));
  };

  const getPromoClickAfterPageRedirect = () => {
    let session = sessionStorage.getItem(sessionStoragePromoClickTracking);

    if (session) {
      session = JSON.parse(session);
      if (session.promotions) {
        const trackData = {
          event: 'promoClick',
          ecommerce: {
            promoClick: {
              promotions: session.promotions,
            }
          }
        };

        if (session.path) {
          trackData.eventSyntax = {
            path: session.path,
          };
        }
        window.dataLayer.push(trackData);
      }
      sessionStorage.removeItem(sessionStoragePromoClickTracking);
    }
  };

  var returnAsArray = function (array) {
    if (!Array.isArray(array)) {
      return [array];
    } else {
      return array;
    }
  };

  /**
   * Push custom event to the dataLayer
   *
   * @param {string} category
   * @param {string} action
   * @param {string} label
   * @param {string} value
   * @param {boolean} nonInteraction
   */
  var pushGaEvent = function (category, action, label, value = '', nonInteraction = false) {
    if (typeof window.dataLayer !== 'undefined') {

      var trackData = {
        event: 'gaEvent',
        eventSyntax: {
          action: action,
          category: category,
          label: label
        }
      };

      if (nonInteraction) {
        trackData.eventSyntax.nonInteraction = 'true';
      }

      if (value) {
        trackData.eventSyntax.value = value;
      }

      window.dataLayer.push(trackData);
    }
  };

  const getGaEventAfterPageRedirect = () => {
    let session = sessionStorage.getItem(sessionStorageGaEventTracking);

    if (session) {
      session = JSON.parse(session);
      if (session.category && session.action && session.label) {
        const trackData = {
          event: 'gaEvent',
          eventSyntax: {
            action: session.action,
            category: session.category,
            label: session.label
          }
        };

        if (session.path) {
          trackData.eventSyntax.path = session.path;
        }

        window.dataLayer.push(trackData);
      }
      sessionStorage.removeItem(sessionStorageGaEventTracking);
    }
  };

  var pushGaEventOnPageRedirect = function (category, action, label) {
    sessionStorage.setItem(sessionStorageGaEventTracking, JSON.stringify({
      category: category,
      action: action,
      label: label,
      path: window.location.pathname + window.location.hash,
    }));
  };

  var pushPurchaseCompleted = function (data, productArray) {
    productArray = returnAsArray(productArray);
    var id = data.id;
    var totalPrice = data.totalPrice.toString();

    push('purchaseComplete', {
      purchase: {
        actionField: {
          id: id,
          revenue: totalPrice
        },
        products: productArray
      }
    });
  };

  var pushProductDetail = function (productArray) {
    productArray = returnAsArray(productArray);
    push('productDetail', {
      detail: {
        products: productArray
      }
    });
  };

  var push = function (event, payload) {
    getDataLayer().push({
      event: event,
      ecommerce: payload
    });
  };

  var getDataLayer = function () {
    if (window.dataLayer) {
      for (var i = 0; i < localDataLayer.length; i++) {
        window.dataLayer.push(localDataLayer[i]);
      }
    }

    return window.dataLayer || localDataLayer;
  };

  var pushUserAccountBalance = function (accountBalance) {
    if (typeof window.dataLayer !== 'undefined') {
      window.dataLayer.push({
        event: 'umPushed',
        user: {
          accountBalance: accountBalance
        }
      });
    }
  };

  var pushConversion = function (action, type, flow, step, value) {
    if (typeof window.dataLayer !== 'undefined') {
      window.dataLayer.push({
        conversion: {
          action: action,
          type: type,
          flow: flow,
          step: step,
          value: value
        }
      });
    }
  };

  var pushJackpots = function (jackpots) {
    if (typeof dataLayer !== 'undefined') {
      var meta = {};

      jackpots.forEach(function (element) {
        if (element.GameId != '') {
          var key = 'pool' + element.GameId.charAt(0).toUpperCase() + element.GameId.slice(1);
          meta[key] = element.PoolSizeDecimal;
        }
      });

      window.dataLayer.push({ meta: meta });
    }
  };

  var getTrackingLabel = function () {
    // Default label
    var label = DS.Config.CONTEXT === 'DLI' ? 'dli_glo' : 'dlo_glo';

    var returnUrl = location.href;

    if (returnUrl) {
      // Strip away hash and querystring
      returnUrl = returnUrl.split('?')[0];
      returnUrl = returnUrl.split('#')[0];

      // Add first segment of URL to label
      var segment = '';
      if (returnUrl.indexOf('://') > 0) {
        segment = returnUrl.split('://')[1].split('/')[1];
      } else {
        segment = returnUrl.split('/')[1];
      }

      // Map to Benjamins terms
      var labelMap = {
        bingo: 'dli_bng',
        casino: 'dli_cas',
        livecasino: 'dli_lic',
        dantoto: 'dli_dan',
        oddset: 'dli_odd',
        poker: 'dli_pok',
        spillehjoernet: 'dli_shj',
        tips: 'dli_tip',
        sikkertspil: 'dli_sis',

        'alt-eller-intet': 'dlo_alt',
        eurojackpot: 'dlo_ejp',
        keno: 'dlo_ken',
        lotto: 'dlo_lot',
        'plus-abonnement': 'dlo_plu',
        'spil-sammen': 'dlo_sps',
        vikinglotto: 'dlo_vik',
        quick: 'dlo_qui',
        virtuel: 'dlo_vrt'
      };

      if (segment && labelMap[segment]) {
        label = labelMap[segment];
      }
    }

    return label;
  };

  var eventUserLoggedIn = function (loginAction) {
    if (!hasPreviouslyTrackedGaEvent('login')) {
      var label = getTrackingLabel();
      pushConversion('true', 'login', 'true', '3', '1');
      pushGaEvent('login', loginAction, label, null, false);  // (category, action, label, value, nonInteraction)
    }
    // Get current user state
    getUserState();

  };

  var eventUserLoggedOut = function () {
    if (typeof window.dataLayer !== 'undefined') {

      window.dataLayer.push({
        event: 'umPushed',
        user: {
          isLoggedIn: 'false',
          dliLoggedIn: 'false',
          dloLoggedIn: 'false',
          idGlobal: '',
          customerId: '',
          accountType: '',
          physicalPlaycardNumber: '',
          digitalPlaycardNumber: ''
        }
      });

    }
  };

  CrossWindowEvents.subscribe('ds.event.userLoggedOut', function () {
    eventUserLoggedOut();
  });

  CrossWindowEvents.subscribe('ds.event.userLoggedIn', function () {
    eventUserLoggedIn();
  });

  var hasPreviouslyTrackedGaEvent = function (category) {
    return window.dataLayer && (dataLayer.filter(function (entry) {
      return entry.event && entry.event === 'gaEvent' && entry.eventSyntax && entry.eventSyntax.category === category;
    }).length > 0);
  };

  var eventBalanceUpdated = function () {
    var userObj = LoginCache.getUserObj();
    if (userObj && userObj.customerInfo) {
      pushUserAccountBalance(userObj.customerInfo.balance.toString());
    }
  };

  var trackFilter = function () {
    // Track download files link:
    const $downloadLinks = document.querySelectorAll('a[href*="media/files"]');
    $downloadLinks.forEach(($element) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      var text = $element.href;
      var editText = text.substring(text.lastIndexOf('/') + 1, text.lastIndexOf('.'));
      $element.addEventListener('click', () => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        pushGaEvent('download', 'click', editText, null, false);
      });
    });

    // Track external links
    const $externalLinks = document.querySelectorAll('a[href^="http"]');
    $externalLinks.forEach(($element) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      const hrefDomain = $element.href.split('/')[2];
      if (hrefDomain !== location.hostname) {
        $element.addEventListener('click', () => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
          pushGaEvent('outbound', 'click', $element.href, null, false);
        });
      }
    });

  };

  var getUserState = function () {

    // Get current user state
    if (typeof window.dataLayer !== 'undefined') {
      var userObjs = LoginCache.getDliDloUserObj(); // user 0 is current brand (dli for front page)
      var DLIUserObj = userObjs[0];
      var DLOUserObj = userObjs[1];
      var dloLoggedIn = DLOUserObj && DLOUserObj.customerInfo && DLOUserObj.customerInfo.userIdHashed;
      var dliLoggedIn = DLIUserObj && DLIUserObj.customerInfo && DLIUserObj.customerInfo.userIdHashed;
      const currentContextUserObj = getContext() === 'DLO' ? DLOUserObj : DLIUserObj;

      var idGlobal = '';
      var customerId = '';
      var accountType = '';
      var physicalPlaycardNumber = '';
      var digitalPlaycardNumber = '';

      // Current brand filler
      if (currentContextUserObj?.customerInfo) {
        const customerInfo = currentContextUserObj.customerInfo;
        idGlobal = customerInfo.userIdHashed;
        customerId = customerInfo.customerId;
        accountType = customerInfo.isRetailAccount ? 'retailer' : 'default';
        physicalPlaycardNumber = customerInfo.physicalPlayCardNumber;
        digitalPlaycardNumber = customerInfo.digitalPlayCardNumber;
      }

      window.dataLayer.push({
        event: 'umPushed',
        user: {
          isLoggedIn: (typeof dliLoggedIn === 'string' || typeof dloLoggedIn === 'string').toString(),
          dliLoggedIn: (typeof dliLoggedIn === 'string').toString(), // userIdHashed
          dloLoggedIn: (typeof dloLoggedIn === 'string').toString(), // userIdHashed
          idGlobal: idGlobal,
          customerId: customerId,
          accountType: accountType,
          physicalPlaycardNumber: physicalPlaycardNumber,
          digitalPlaycardNumber: digitalPlaycardNumber
        }
      });
    }
  };

  /**
   * This function tracks clickable elements on a webpage. It identifies elements with a 'data-tracking' attribute and its child elements with a 'data-tracking-label' attribute.
   * It adds a click event listener to these elements and retrieves the category and action from the element's attributes.
   * Depending on the presence and value of the href attribute, it calls either 'pushGaEventOnPageRedirect' or 'pushGaEvent' function with the category, action, and label.
   */
  const setupFeatureModulesTracking = () => {
    const trackableModules = document.querySelectorAll("[data-tracking]:not([data-tracking=''])");

    trackableModules.forEach((trackableComponent) => {
      const trackableElements = trackableComponent.querySelectorAll('[data-tracking-label]');

      trackableElements.forEach((element) => {
        const label = element.getAttribute('data-tracking-label');

        if (label) {
          element.addEventListener('click', () => {
            const category =
              element.getAttribute('data-tracking-category') ||
              trackableComponent.getAttribute('data-tracking');

            const action = element.getAttribute('data-tracking-action') || 'click';

            if (element.href && element.href !== '#') {
              pushGaEventOnPageRedirect(category, action, label);
            } else {
              pushGaEvent(category, action, label);
            }
          });
        }
      });
    });
  };

  var initialize = function () {
    // Add tracker to click event
    trackFilter();

    // Get current user state
    Event.subscribe('pageload-auth-synced', getUserState);

    // Check if DSAPI exist
    if (typeof DSAPI !== 'undefined') {
      DSAPI.ready(function () {

        // Hook on login click
        document.querySelectorAll('.js-login-header').forEach(($element) => {
          $element.addEventListener('click', () => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            pushConversion('true', 'login', 'true', '1', '1');
          });
        });
      });
    }

    // Track login times
    var loginTime = sessionStorage.getItem(DS.Config.CONTEXT + '-loginTime');
    var loginMethod = Utils.getParameter('loginMethod');

    if (loginTime) {
      var label = getTrackingLabel();
      var loginTimeAsInt = parseInt(loginTime, 10);
      pushGaEvent('measured_' + label, 'loginTime_success_' + loginMethod, new Date(loginTimeAsInt).toString(), loginTimeAsInt);
      sessionStorage.removeItem(DS.Config.CONTEXT + '-loginTime');
    }

    // Check if we need to 'pick up' a gaEvent or promoClick that was logged just before page redirect.
    getGaEventAfterPageRedirect();
    getPromoClickAfterPageRedirect();
    setupFeatureModulesTracking();
  };

  if (/loaded|complete/.test(document.readyState)) {
    initialize();
  } else {
    document.addEventListener('DOMContentLoaded', initialize);
  }

  /* Public interface */
  return {
    getBrand,
    pushVirtualPage,
    pushHashAsVirtualPage,
    pushGaEvent,
    pushGaEventOnPageRedirect,
    pushConversion,
    pushPromoView,
    pushPromoClick,
    pushPromoClickOnPageRedirect,
    pushJackpots,
    pushPurchaseCompleted,
    pushProductDetail,
    eventUserLoggedIn,
    eventBalanceUpdated,
  };

});
