defineDs('DanskeSpil/Domain/Header/Scripts/HeaderNotifications', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  'Shared/Framework/Mithril/Scripts/Helpers/DOMUtils',
  'Shared/Framework/Mithril/Scripts/Helpers/Dictionary',
  'Shared/Framework/Mithril/Scripts/Helpers/Storage',
  'Common/Framework/EventHandling/Scripts/Event',
  'Common/Framework/EventHandling/Scripts/CrossWindowEvents',
  'DanskeSpil/Domain/Authentification/Scripts/LoginCache',
  'DanskeSpil/Domain/Authentification/Scripts/SitecoreAuth',
], function (Utils, DOMUtils, Dictionary, Storage, Event, CrossWindowEvents, LoginCache, SitecoreAuth) {

  var notifications = {};
  var authenticationDictionary = null; // For lazy-loading purpose, this will be initialized from getDictionaryText.

  // Returns boolean depending on whether or not the localStorage contains the lastLoginHash
  var shouldShowLastLogin = function () {
    const now = (new Date).getTime();
    const lastLoginExpiration = Number(Storage.get('lastLoginExpiration'));
    const cookieValue = Utils.cookie('BOSSOisLoggedIn' + DS.Config.BOSSOCONTEXT);

    if (Storage.get('lastLoginHash') !== cookieValue) {
      Storage.set('lastLoginHash', cookieValue);
      Storage.set('lastLoginExpiration', now + 30000);
      return true;
    } else if (now <= lastLoginExpiration) {
      return true;
    }
    return false;
  };

  var getDictionaryText = function (dictionaryElement, callback) {
    // Lazy-loading: If dictionary is not initialized yet, please do:
    if (!authenticationDictionary) {
      authenticationDictionary = new Dictionary('/Authentification');
    }

    authenticationDictionary.ready.then(function () {
      var text = authenticationDictionary.get(dictionaryElement);
      if (text) {
        callback(text);
      }
    });
  };

  var gameScannerNotification = function (user) {
    var changes = 0;

    // game scan (dataprocessing)
    if (!user.customerInfo.hasConsentedToDataprocessing && !user.customerInfo.isRetailAccount) {
      getDictionaryText('GameScanNotAccepted', function (text) {
        changes += addNotification({ id: 'gamescanner-notification', icon: 'error', type: 'user', text: text, closable: true, dismissable: false }) ? 1 : 0;
      });
    } else {
      changes += removeNotification('gamescanner-notification') ? 1 : 0;
    }

    // and pending nofification
    if (user.customerInfo.status === 'Pending' && !user.customerInfo.isRetailAccount) {
      getDictionaryText('UserPending', function (text) {
        changes += addNotification({ id: 'pending-notification', icon: 'error', type: 'user', text: text, closable: true, dismissable: false }) ? 1 : 0;
      });
    } else {
      changes += removeNotification('pending-notification') ? 1 : 0;
    }

    if (changes > 0) {
      Event.fire('header-area-height-changed');
    }
  };

  var getUserInfo = function (callback) {
    var userObj = LoginCache.getUserObj();
    if (!userObj || !userObj.customerInfo) {
      SitecoreAuth.getUserObj(function (data) {
        if (data && data.customerInfo) {
          callback(null, data);
        } else {
          callback(true, null);
        }
      });
    } else {
      callback(null, userObj);
    }
  };

  var userUpdated = function () {
    getUserInfo(function (err, user) {
      if (!err) {
        gameScannerNotification(user);
        // geoRestrictedNotification(user);
        if (user.customerInfo.prevLoginTime && shouldShowLastLogin()) {
          // The time the notification is shown should be 30s regardless of how many pageloads are made. Thus we must calculate the remaining time when we set the notification
          const lastLoginExpiration = Number(Storage.get('lastLoginExpiration'));
          const now = (new Date()).getTime();
          const time = (lastLoginExpiration - now) / 1000;

          getDictionaryText('LastLoginDateLabel', function (label) {
            if (addNotification({ id: 'latest-login-time', icon: 'info', timeShown: time, type: 'user', dismissable: false, text: label + ' ' + user.customerInfo.prevLoginTime, extraTextClasses: 'js-notificaton-prevlogintime' })) {
              Event.fire('header-area-height-changed');
            }
          });

        }
      } else {
        removeNotifications('user');
        Event.fire('header-area-height-changed');
      }
    });
  };

  var bindEvents = function () {
    CrossWindowEvents.subscribe('ds.event.getUserDataFromSitecore', function () {
      initHeaderNotifications(true);
    });

    CrossWindowEvents.subscribe('ds.event.userLoggedOut', function () {
      removeNotifications('user');
    });

    Event.subscribe('user-data-refreshed', function () {
      userUpdated();
    });
  };

  var calculateNotificationsStaticFixDiv = function () {
    // We need a setTimeout because the display state is not updated right away (show/hide is called right before this)
    setTimeout(function () {
      var $staticFix = document.querySelector('.notifications-static-fix');
      var $notifications = document.querySelector('.notifications');

      if (!$staticFix || !$notifications) {
        return;
      }

      var totalHeight = DOMUtils.calculateElementOuterHeight($notifications);
      $staticFix.style.height = totalHeight + 'px';
    }, 300);
  };

  var isNotificationDismissed = function (id) {
    var key = 'notifications-item-' + id;
    return Storage.get(key) === 'dismissed';
  };

  var storeNotificationDismiss = function (id) {
    var key = 'notifications-item-' + id;
    Storage.set(key, 'dismissed', 7 * 24 * 60 * 60);
  };

  var addNotification = function (callParams) {
    var params = {
      id: Math.random().toString(16).slice(2).toString(),
      closable: true,
      timeShown: Infinity,
      extraClasses: '',
      extraTextClasses: '',
      icon: 'info',
      type: 'default',
      dismissable: true,
      ...callParams
    };

    // If notification is earlier dismissed according to localStorage, the user has dismissed the notification it should not be shown
    if (params.closable === true && isNotificationDismissed()) {
      return false;
    }

    var notificationHTML = '<div class="notifications-item ' + params.icon + '-notification" data-notification-type="' + params.type + '" data-notification-dismissable="' + params.dismissable + '" data-notificationid="' + params.id + '"><div class="notifications-item-inner ' + params.extraClasses + '"><span class="' + params.extraTextClasses + '">' + params.text + '</span>';
    if (params.closable) {
      notificationHTML += '<button class="close-btn"></button>';
    }
    notificationHTML += '</div></div>';

    if (notifications[params.id]) {
      removeNotification(params.id);
    }

    const range = document.createRange();
    notifications[params.id] = range.createContextualFragment(notificationHTML);
    const $notificationsTop = document.querySelector('[data-notifications="top"]');

    if ($notificationsTop) {
      $notificationsTop.append(notifications[params.id]); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    }

    const closeButton = document.querySelector('[data-notificationid="' + params.id + '"] .close-btn');

    closeButton?.addEventListener('click', (ev) => {
      ev.preventDefault();

      if (params.id === 'latest-login-time') {
        // expire now instead of after remaning time of initial 30 seconds
        var date = new Date();
        Storage.set('lastLoginExpiration', date.getTime());
      }

      removeNotification(params.id);

      Event.fire('header-area-height-changed');
      calculateNotificationsStaticFixDiv();
    });

    const notificationEl = document.querySelector('[data-notificationid="' + params.id + '"]');

    if (notificationEl) {
      notificationEl.style.display = 'block';
    }

    if (params.timeShown !== Infinity) {

      if (params.timeShown < 0) {
        params.timeShown = 30;
      }

      setTimeout(function () {
        removeNotification(params.id);
        Event.fire('header-area-height-changed');
        calculateNotificationsStaticFixDiv();
      }, params.timeShown * 1000);
    }
    calculateNotificationsStaticFixDiv();

    return true;
  };

  var removeNotification = function (id) {
    var $notification = document.querySelector('[data-notificationid="' + id + '"]');

    if (!$notification) {
      return false;
    }

    if ($notification.dataset.notificationDismissable === 'true') {
      // User dismissed Notification so we can set Cookie to prevent it from being shown again
      storeNotificationDismiss(id);
    }

    $notification.remove(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    delete notifications[id];
    pamPageHelper();// Align pam page according to header height
    return true; // true means that the notification is actually removed
  };

  var removeNotifications = function (type) {
    var count = 0;
    document.querySelectorAll('.notifications-item').forEach(($element) => {
      var id = $element.dataset.notificationid || '';
      if (!type || $element.dataset.notificationType === type) {
        if (removeNotification(id)) {
          count += 1;
        }
      }
    });

    if (count > 0) {
      Event.fire('header-area-height-changed');
      calculateNotificationsStaticFixDiv();
    }
  };

  const pamPageHelper = function () {
    const $activeSlideElem = document.querySelector('.pam-receipt-page.route-slide--active');
    if (!$activeSlideElem) {
      return;
    }

    const $header = document.querySelector('.header');
    const headerHeight = DOMUtils.calculateElementOuterHeight($header);

    if (!headerHeight) {
      return;
    }

    $activeSlideElem.style.paddingTop = headerHeight + 'px';
  };

  var bindCloseNotification = function () {
    document.querySelectorAll('.notifications-item').forEach(($element) => {
      $element.style.display = 'none';

      $element.querySelector('.notifications-item-close-button')?.addEventListener('click', (ev) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        ev.preventDefault();
        $element.style.display = 'none';
        const id = $element.dataset.notificationid || '';
        calculateNotificationsStaticFixDiv();

        storeNotificationDismiss(id);
        pamPageHelper();// Align pam page according to header height

        Event.fire('header-area-height-changed');
      });
    });
  };

  var initHeaderNotifications = function (triggerUserUpdated) {
    setTimeout(function () {
      var count = 0;
      document.querySelectorAll('.notifications-item').forEach((elem) => {
        var id = elem.dataset.notificationid || '';

        if (!isNotificationDismissed(id)) {
          elem.style.display = 'block';
        }
      });

      if (count > 0) {
        calculateNotificationsStaticFixDiv();
        Event.fire('header-area-height-changed');

      }
    }, 300);

    var documentReady = () => {
      if (LoginCache.isLoggedIn() || triggerUserUpdated) {
        userUpdated();
      }
    };

    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', documentReady);
    } else {
      documentReady();
    }

    bindCloseNotification();
    calculateNotificationsStaticFixDiv();
  };

  // Init:
  var initialize = function () {
    window.addEventListener('resize', () => {
      calculateNotificationsStaticFixDiv();
    });

    bindEvents();
    initHeaderNotifications();
  };

  initialize();

  return {
    gameScannerNotification: gameScannerNotification
  };
});
